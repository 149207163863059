import { dayjs } from '@attendu/utils';

export default ({
  date,
  time,
  timezone,
}) => {
  const timezoneDate = dayjs(date).tz(timezone).format('YYYY-MM-DD');

  return dayjs.tz(`${timezoneDate}T${time}`, timezone).utc().format();
};
